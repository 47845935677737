import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: 1px solid var(--primary-text);
  background-color: var(--primary);
  padding: 15px;
  font-family: "Acumin Pro";
  font-weight: bold;
  color: var(--secondary-text);
  cursor: pointer;
  :hover {
    background-color: var(--secondary);
    border: 1px solid var(--primary);
    transition-duration: 0.4s;
  }
  :disabled {
    background-color: var(--accent);
    border: none;
    cursor: initial;
  }
  transition-duration: 0.4s;
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 32px;
  margin: 4px;
  @media (min-width: 767px) {
    width: 48px;
    margin: 8px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  @media (min-width: 2000px) {
    width: 500px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [hasApprovedUSDC, setHasApprovedUSDC] = useState(false);
  const [isApprovingUSDC, setIsApprovingUSDC] = useState(false);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState({
    text: `Click buy to mint your NFT.`,
    status: "neutral",
  });
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG_ERC20, SET_CONFIG_ERC20] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });
  const [CONFIG_SALE, SET_CONFIG_SALE] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  console.log(data);

  const approveUSDC = () => {
    let cost = CONFIG_ERC20.WEI_COST;
    setIsApprovingUSDC(true);
    blockchain.erc20.methods
      .approve(CONFIG_SALE.CONTRACT_ADDRESS, "1500000000")
      .send({
        gasPrice: String(cost),
        from: blockchain.account,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback({
          text: "Sorry, something went wrong please try again later.",
          status: "error",
        });
        setIsApprovingUSDC(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback({ text: `Successfully approved USDC.`, status: "success" });
        setIsApprovingUSDC(false);
        setHasApprovedUSDC(true);
        dispatch(fetchData(blockchain.account));
      });
  };

  const claimNFTs = () => {
    let cost = CONFIG_ERC20.WEI_COST;
    // let gasLimit = CONFIG_ERC20.GAS_LIMIT;
    // let totalCostWei = String(cost * mintAmount);
    // let totalGasLimit = String(gasLimit * mintAmount);
    // console.log("Cost: ", totalCostWei);
    // console.log("Gas limit: ", totalGasLimit);
    setFeedback({
      text: `Minting your ${CONFIG_ERC20.NFT_NAME}...`,
      status: "neutral",
    });
    setClaimingNft(true);
    console.log(blockchain.account);

    blockchain.sale.methods
      .batchPurchase(Array(mintAmount).fill(0))
      .send({
        gasPrice: String(cost),
        to: CONFIG_SALE.CONTRACT_ADDRESS,
        from: blockchain.account,
        // value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback({
          text: "Sorry, something went wrong please try again later.",
          status: "error",
        });
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback({
          text: `Successfully minted your ${CONFIG_ERC20.NFT_NAME}.`,
          status: "success",
        });
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 5) {
      newMintAmount = 5;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.erc20 !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configErc20Response = await fetch("/config/erc20/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const configErc20 = await configErc20Response.json();
    SET_CONFIG_ERC20(configErc20);

    const configSaleResponse = await fetch("/config/sale/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const configSale = await configSaleResponse.json();
    SET_CONFIG_SALE(configSale);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 24, backgroundColor: "var(--primary)" }}
        image={CONFIG_ERC20.SHOW_BACKGROUND ? "/config/images/bg.png" : null}
      >
        <s.Container flex={1} jc="space-between" ai="baseline" fd="row">
          <a href="https://themissiondao.com/">
            <StyledLogo src="config/images/MDAO-logo-FA-03.png" />
          </a>
          <s.Container flex={1} jc="right" ai="center" fd="row">
            <a href="https://opensea.io/collection/missiondao">
              <StyledLogo src="config/images/opensea-icon-light.svg" />
            </a>
            <a href="https://twitter.com/missiondao">
              <StyledLogo src="config/images/twitter-light.svg" />
            </a>
            <a href="http://bit.ly/MISSIONDAO_DISCORD">
              <StyledLogo src="config/images/discord-light.svg" />
            </a>
            <a href="http://instagram.com/missiondao">
              <StyledLogo src="config/images/insta-light.svg" />
            </a>
          </s.Container>
        </s.Container>
        <a href={CONFIG_ERC20.MARKETPLACE_LINK}>
          {/* <StyledLogo alt={"logo"} src={"/config/images/logo.png"} /> */}
        </a>
        <s.Container flex={1} jc="center" ai="center" fd="row">
          <StyledImg
            src={"/config/images/MDAO-NFTCard-PreReveal-220303-01.png"}
          />
          <StyledImg
            src={"/config/images/MDAO-NFTCard-PreReveal-220303-01.png"}
          />
          <StyledImg
            src={"/config/images/MDAO-NFTCard-PreReveal-220303-01.png"}
          />
          <StyledImg
            src={"/config/images/MDAO-NFTCard-PreReveal-220303-01.png"}
          />
          <StyledImg
            src={"/config/images/MDAO-NFTCard-PreReveal-220303-01.png"}
          />
        </s.Container>
        <s.SpacerSmall />
        <ResponsiveWrapper flex={1} style={{ padding: 24 }} test>
          <s.Container flex={2} jc={"center"} ai={"center"}>
            {blockchain.account === "" || blockchain.erc20 === null ? (
              <s.Container ai={"center"} jc={"center"}>
                <s.TextDescription
                  style={{
                    textAlign: "center",
                    color: "var(--accent-text)",
                  }}
                >
                  Connect to the {CONFIG_ERC20.NETWORK.NAME} network
                </s.TextDescription>
                <s.SpacerSmall />
                <StyledButton
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(connect());
                    getData();
                  }}
                >
                  CONNECT METAMASK WALLET
                </StyledButton>
                {blockchain.errorMsg !== "" ? (
                  <>
                    <s.SpacerSmall />
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {blockchain.errorMsg}
                    </s.TextDescription>
                  </>
                ) : null}
              </s.Container>
            ) : 
            // Conditionally render when totalSupply is loaded. 
            (data.totalSupply ?
              <>
                <s.TextSubTitle>Total Supply</s.TextSubTitle>
                <s.TextTitle
                  style={{
                    textAlign: "center",
                    fontSize: 36,
                    fontWeight: "bold",
                    color: "var(--accent-text)",
                  }}
                >
                  {data.totalSupply - data.currentNumber + 1} / {data.totalSupply}
                </s.TextTitle>
                <s.TextDescription
                  style={{
                    textAlign: "center",
                    color: "var(--primary-text)",
                  }}
                >
                  <StyledLink target={"_blank"} href={CONFIG_SALE.SCAN_LINK}>
                    {truncate(CONFIG_SALE.CONTRACT_ADDRESS, 15)}
                  </StyledLink>
                </s.TextDescription>
                <s.SpacerSmall />
                {/* current number - 1 gives us the current number minted */}
                {Number(data.currentNumber - 1) >= Number(data.totalSupply) ? (
                  <>
                    <s.TextTitle
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      The sale has ended.
                    </s.TextTitle>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      You can still find {CONFIG_ERC20.NFT_NAME} on
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <StyledLink
                      target={"_blank"}
                      href={CONFIG_ERC20.MARKETPLACE_LINK}
                    >
                      {CONFIG_ERC20.MARKETPLACE}
                    </StyledLink>
                  </>
                ) : (
                  <>
                    {/* <s.TextTitle
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      1 {CONFIG_ERC20.SYMBOL} costs {CONFIG_ERC20.DISPLAY_COST}{" "}
                      {CONFIG_ERC20.NETWORK.SYMBOL}.
                    </s.TextTitle>
                    <s.SpacerXSmall />
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      Excluding gas fees.
                    </s.TextDescription>
                    <s.SpacerSmall /> */}
                    {!hasApprovedUSDC ? (
                      <s.Container ai={"center"} jc={"center"} fd={"row"}>
                        <StyledButton
                          disabled={isApprovingUSDC ? 1 : 0}
                          onClick={(e) => {
                            e.preventDefault();
                            approveUSDC();
                            getData();
                          }}
                        >
                          {isApprovingUSDC ? "BUSY" : "APPROVE USDC"}
                        </StyledButton>
                      </s.Container>
                    ) : (
                      <>
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color:
                              feedback?.status === "success"
                                ? "var(--success-text)"
                                : feedback?.status === "error"
                                ? "var(--error-text)"
                                : "var(--accent-text)",
                          }}
                        >
                          {feedback?.text}
                        </s.TextDescription>
                        <s.SpacerMedium />
                        <s.Container ai={"center"} jc={"center"} fd={"row"}>
                          <StyledRoundButton
                            style={{ lineHeight: 0.4, fontSize: "16px" }}
                            disabled={claimingNft ? 1 : 0}
                            onClick={(e) => {
                              e.preventDefault();
                              decrementMintAmount();
                            }}
                          >
                            -
                          </StyledRoundButton>
                          <s.SpacerMedium />
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                              border: "1px solid var(--primary-text)",
                              borderRadius: "50px",
                              padding: "8px 20px",
                            }}
                          >
                            {mintAmount}
                          </s.TextDescription>
                          <s.SpacerMedium />
                          <StyledRoundButton
                            style={{ lineHeight: 0.4, fontSize: "16px" }}
                            disabled={claimingNft ? 1 : 0}
                            onClick={(e) => {
                              e.preventDefault();
                              incrementMintAmount();
                            }}
                          >
                            +
                          </StyledRoundButton>
                        </s.Container>
                        <s.SpacerSmall />

                        <s.Container ai={"center"} jc={"center"} fd={"row"}>
                          <StyledButton
                            disabled={claimingNft ? 1 : 0}
                            onClick={(e) => {
                              e.preventDefault();
                              claimNFTs();
                              getData();
                            }}
                          >
                            {claimingNft ? "MINTING" : "MINT NFT"}
                          </StyledButton>
                        </s.Container>
                      </>
                    )}
                  </>
                )}
                <s.SpacerMedium />
              </> : <></>
            )}
          </s.Container>
        </ResponsiveWrapper>
        <s.SpacerMedium />
        <s.Container jc={"center"} ai={"center"} style={{ width: "70%" }}>
          <s.TextDescription
            style={{
              textAlign: "center",
              color: "var(--primary-text)",
            }}
          >
            Please make sure you are connected to the right network (
            {CONFIG_ERC20.NETWORK.NAME} Mainnet) and the correct address. Please
            note: Once you make the purchase, you cannot undo this action.
          </s.TextDescription>
        </s.Container>
      </s.Container>
    </s.Screen>
  );
}

export default App;
